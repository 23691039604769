.App {
  text-align: center;
}

.cardStyle {
  padding: 0 !important;
  background: red;
}

.ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(2)
}

/* #1677ff */

/* Add this to your CSS file */
.draggable-item {
  user-select: none;
  padding: 1px;
  margin: 0 0 8px 0;
  /* background: red; */
  /* height: 100px; */
  /* border: 4px solid #40a9ff; */
  border: 4px solid #0092E4;
  /* box-shadow: 0 0 .05rem #69c0ff,
    0 0 .05rem #69c0ff,
    0 0 0.5rem #69c0ff,
    0 0 0.2rem #69c0ff,
    0 0 0.7rem #69c0ff,
    inset 0 0 0.3rem #69c0ff; */
}

.droppable-area {
  background: #f0f0f0;
  padding: 8px;
  border: 1px solid #ddd;
}